let TimeZone = getCurrentZone();
/**
 * 格式化时间
 * @param time
 * @param type
 */
export function formatDate(time, type = "yyyy-MM-dd HH:mm:ss") {
  const date = new Date(time);
  const yy = date.getFullYear();
  const MM = add0(date.getMonth() + 1);
  const dd = add0(date.getDate());
  const HH = add0(date.getHours());
  const mm = add0(date.getMinutes());
  const ss = add0(date.getSeconds());

  if (type === "HH:mm:ss") {
    return `${HH}:${mm}:${ss}`;
  } else if (type === "yy-MM-dd") {
    return `${yy}-${MM}-${dd}`;
  } else if (type === "yy-MM-dd HH:mm") {
    return `${yy}-${MM}-${dd} ${HH}:${mm}`;
  }

  return `${yy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
}

/**
 * 秒转时分秒
 * @param n
 */

export function timeFormat(s) {
  let hours = Math.floor(s / 3600);
  let minutes = Math.floor(s / 60) % 60;
  let seconds = s % 60;
  return (
    (hours < 10 ? "0" + hours : hours) +
    ":" +
    (minutes < 10 ? "0" + minutes : minutes) +
    ":" +
    (seconds < 10 ? "0" + seconds : seconds)
  );
}

/**
 * 小于10 添加0
 * @param n
 */
export function add0(n) {
  if (n < 10) {
    return "0" + n;
  } else {
    return n;
  }
}

/**
 * 获取指定时区时间
 * @param timezone
 */
// public static getTimeZoneDate(timezone: number) {
//   let offsetGMT = new Date().getTimezoneOffset() // 本地时间和格林威治的时间差，单位为分钟
//   let nowDate = new Date().getTime() // 本地时间距 1970 年 1 月 1 日午夜（GMT 时间）之间的毫秒数
//   let targetDate = new Date(nowDate + offsetGMT * 60 * 1000 + timezone * 60 * 60 * 1000)
//   return targetDate
// }

/**
 * 根据传入时间转换差异时区时间
 * @param date
 * @param timezone
 */
export function getTimeZoneDate2(timestamp) {
  // if (!date) {
  //   return "";
  // }
  if (!timestamp) {
    return "";
  }
  // let timestamp = date.getTime();
  let targetDate = new Date(timestamp + (TimeZone - 8) * 60 * 60 * 1000);
  return formatDate(+new Date(targetDate));
}

export function getTimeZoneDate(date) {
  if (!date || date === "") {
    return date;
  } else if (typeof date === "string") {
    date = date.replace(/-/g, "/");
  }
  return getTimeZoneDate2(new Date(date).getTime());
}

/**
 * 获取当前时区
 */
export function getCurrentZone() {
  // 获取时区 //目标时区时间，东八区   东时区正数 西市区负数
  return (new Date().getTimezoneOffset() / 60) * -1;
}

export function getZoneName() {
  let tmSummer = new Date(Date.UTC(2005, 6, 30, 0, 0, 0, 0));
  let so = -1 * tmSummer.getTimezoneOffset();
  let tmWinter = new Date(Date.UTC(2005, 12, 30, 0, 0, 0, 0));
  let wo = -1 * tmWinter.getTimezoneOffset();
  if (-660 === so && -660 === wo) {
    return "Pacific/Midway";
  }
  if (-600 === so && -600 === wo) {
    return "Pacific/Tahiti";
  }
  if (-570 === so && -570 === wo) {
    return "Pacific/Marquesas";
  }
  if (-540 === so && -600 === wo) {
    return "America/Adak";
  }
  if (-540 === so && -540 === wo) {
    return "Pacific/Gambier";
  }
  if (-480 === so && -540 === wo) {
    return "US/Alaska";
  }
  if (-480 === so && -480 === wo) {
    return "Pacific/Pitcairn";
  }
  if (-420 === so && -480 === wo) {
    return "US/Pacific";
  }
  if (-420 === so && -420 === wo) {
    return "US/Arizona";
  }
  if (-360 === so && -420 === wo) {
    return "US/Mountain";
  }
  if (-360 === so && -360 === wo) {
    return "America/Guatemala";
  }
  if (-360 === so && -300 === wo) {
    return "Pacific/Easter";
  }
  if (-300 === so && -360 === wo) {
    return "US/Central";
  }
  if (-300 === so && -300 === wo) {
    return "America/Bogota";
  }
  if (-240 === so && -300 === wo) {
    return "US/Eastern";
  }
  if (-240 === so && -240 === wo) {
    return "America/Caracas";
  }
  if (-240 === so && -180 === wo) {
    return "America/Santiago";
  }
  if (-180 === so && -240 === wo) {
    return "Canada/Atlantic";
  }
  if (-180 === so && -180 === wo) {
    return "America/Montevideo";
  }
  if (-180 === so && -120 === wo) {
    return "America/Sao_Paulo";
  }
  if (-150 === so && -210 === wo) {
    return "America/St_Johns";
  }
  if (-120 === so && -180 === wo) {
    return "America/Godthab";
  }
  if (-120 === so && -120 === wo) {
    return "America/Noronha";
  }
  if (-60 === so && -60 === wo) {
    return "Atlantic/Cape_Verde";
  }
  if (0 === so && -60 === wo) {
    return "Atlantic/Azores";
  }
  if (0 === so && 0 === wo) {
    return "Africa/Casablanca";
  }
  if (60 === so && 0 === wo) {
    return "Europe/London";
  }
  if (60 === so && 60 === wo) {
    return "Africa/Algiers";
  }
  if (60 === so && 120 === wo) {
    return "Africa/Windhoek";
  }
  if (120 === so && 60 === wo) {
    return "Europe/Amsterdam";
  }
  if (120 === so && 120 === wo) {
    return "Africa/Harare";
  }
  if (180 === so && 120 === wo) {
    return "Europe/Athens";
  }
  if (180 === so && 180 === wo) {
    return "Africa/Nairobi";
  }
  if (240 === so && 180 === wo) {
    return "Europe/Moscow";
  }
  if (240 === so && 240 === wo) {
    return "Asia/Dubai";
  }
  if (270 === so && 210 === wo) {
    return "Asia/Tehran";
  }
  if (270 === so && 270 === wo) {
    return "Asia/Kabul";
  }
  if (300 === so && 240 === wo) {
    return "Asia/Baku";
  }
  if (300 === so && 300 === wo) {
    return "Asia/Karachi";
  }
  if (330 === so && 330 === wo) {
    return "Asia/Calcutta";
  }
  if (345 === so && 345 === wo) {
    return "Asia/Katmandu";
  }
  if (360 === so && 300 === wo) {
    return "Asia/Yekaterinburg";
  }
  if (360 === so && 360 === wo) {
    return "Asia/Colombo";
  }
  if (390 === so && 390 === wo) {
    return "Asia/Rangoon";
  }
  if (420 === so && 360 === wo) {
    return "Asia/Almaty";
  }
  if (420 === so && 420 === wo) {
    return "Asia/Bangkok";
  }
  if (480 === so && 420 === wo) {
    return "Asia/Krasnoyarsk";
  }
  if (480 === so && 480 === wo) {
    return "Beijing";
  } // return 'Australia/Perth'
  if (540 === so && 480 === wo) {
    return "Asia/Irkutsk";
  }
  if (540 === so && 540 === wo) {
    return "Asia/Tokyo";
  }
  if (570 === so && 570 === wo) {
    return "Australia/Darwin";
  }
  if (570 === so && 630 === wo) {
    return "Australia/Adelaide";
  }
  if (600 === so && 540 === wo) {
    return "Asia/Yakutsk";
  }
  if (600 === so && 600 === wo) {
    return "Australia/Brisbane";
  }
  if (600 === so && 660 === wo) {
    return "Australia/Sydney";
  }
  if (630 === so && 660 === wo) {
    return "Australia/Lord_Howe";
  }
  if (660 === so && 600 === wo) {
    return "Asia/Vladivostok";
  }
  if (660 === so && 660 === wo) {
    return "Pacific/Guadalcanal";
  }
  if (690 === so && 690 === wo) {
    return "Pacific/Norfolk";
  }
  if (720 === so && 660 === wo) {
    return "Asia/Magadan";
  }
  if (720 === so && 720 === wo) {
    return "Pacific/Fiji";
  }
  if (720 === so && 780 === wo) {
    return "Pacific/Auckland";
  }
  if (765 === so && 825 === wo) {
    return "Pacific/Chatham";
  }
  if (780 === so && 780 === wo) {
    return "Pacific/Enderbury";
  }
  if (840 === so && 840 === wo) {
    return "Pacific/Kiritimati";
  }
  return "US/Pacific";
}

export function formatTimeZone(time, offset) {
  var d = new Date(time); //创建一个Date对象 time时间 offset 时区 中国为 8
  var localTime = d.getTime(); //获取的是毫秒级
  var localOffset = d.getTimezoneOffset() * 60000; //获得当地时间偏移的毫秒数,时区是以分钟为单位的
  var utc = localTime + localOffset; //utc即GMT时间,世界时,格林威治时间
  var wishTime = utc + 3600000 * offset;
  return new Date(wishTime);
}

//比对时间是否在区间内
export function testTimeInterval(openTime, closeTime, nowTimehms) {
  let bagin_ = openTime.split(":");
  let end_ = closeTime.split(":");
  let now_ = nowTimehms.split(":");
  let bagin_Temp =
    Number(bagin_[0] * 3600) + Number(bagin_[1] * 60) + Number(bagin_[2]);
  let end_Temp =
    Number(end_[0] * 3600) + Number(end_[1] * 60) + Number(end_[2]);
  let now_Temp =
    Number(now_[0] * 3600) + Number(now_[1] * 60) + Number(now_[2]);
  console.log(bagin_Temp, end_Temp, now_Temp);
  // 跨天
  if (end_Temp - bagin_Temp < 0) {
    //跨天加1天
    if (bagin_Temp >= now_Temp) {
      return true;
    } else {
      if (end_Temp <= now_Temp) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (bagin_Temp <= now_Temp && now_Temp <= end_Temp) {
      return true;
    } else {
      return false;
    }
  }
}

// 获取对应时区的日期
export function getUsTime(nowTime, type, timeZoneType) {
  // 创建一个日期对象，获取当前日期和时间
  const currentDate = nowTime;
  // 指定目标时区为美国东部标准时间（EST）或夏令时（EDT）
  const usTimeZone =
    timeZoneType === "us" ? "America/New_York" : "Asia/Shanghai";
  // 创建一个日期格式化器，指定时区和日期格式
  const formatter = new Intl.DateTimeFormat(
    timeZoneType === "us" ? "en-US" : "zh-CN",
    {
      hourCycle: "h24",
      timeZone: usTimeZone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }
  );
  // 格式化日期
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: second },
  ] = formatter.formatToParts(currentDate);
  // 创建 "YYYY-MM-DD"或者"HH:mm:ss" 格式的日期字符串
  if (type == "YYYY-MM-DD") {
    return timeZoneType === "us"
      ? `${year}-${month}-${day}`
      : `${month}-${day}-${year}`;
  } else {
    return `${hour}:${minute}:${second}`;
  }
}
